<div class="card-container">
  <nz-avatar nzIcon="user" nzSrc="../assets/profile.jpg"></nz-avatar>
  <h2>Joe Langenderfer</h2>
  <p>
    <span role="img" aria-label="Home">🏠</span>&nbsp;Denver, CO |
    <span role="img" aria-label="Home">&nbsp;🎓</span>&nbsp;Boston College |
    <span role="img" aria-label="Current">&nbsp;📍</span>&nbsp;Boston, MA
  </p>
  <div>
    <a
      aria-label="Some Music"
      rel="noopener"
      target="_blank"
      href="https://open.spotify.com/user/joelangen"
      title=""
    >
      <button id="timeline"><p>Stay Tuned</p></button>
    </a>
    <!-- <button id="timeline"> <p>Timeline</p> </button>
        <button id="stories"> <p>Stories</p> </button> -->
  </div>
</div>
