<div class="content" role="main">
  <!-- Nav -->
  <div class="nav" role="banner">
    <a aria-label="Joe Logo" rel="noopener" href="/" title="">
      <div id="lifeof-logo">
        <h2>lifeof</h2>
        <p>joe</p>
      </div>
    </a>
    <div class="spacer"></div>
    <div class="menu-items">
      <!-- <a aria-label="Joe 2" target="_blank" rel="noopener" href="=" title="">
                <p id="menu-item menu-item-left">Images</p>
              </a>
              <a aria-label="Joe 2" target="_blank" rel="noopener" href="=" title="">
                <p id="menu-item">Favorites</p>
              </a>
              <a aria-label="Joe 3" target="_blank" rel="noopener" href="" title="">
                <p id="menu-item-right">Quotes</p>
              </a> -->
      <a aria-label="Joe 3" rel="noopener" href="/" title="">
        <p id="temp-menu-item-right">More Coming Soon</p>
      </a>
    </div>
  </div>

  <!-- Profile -->
  <app-profile-card></app-profile-card>

  <!-- Footer -->
  <div class="footer" role="banner">
    <div class="small-footer-content">
      <a
        aria-label="Email Me"
        target="_blank"
        rel="noopener"
        href="mailto:joe.m.langen@gmail.com?Subject=Joe%20+%20[Your%20Name]&amp;body=If%20you%20have%20a%20cool%20startup%20or%20startup%20idea,%20I'd%20love%20to%20grab%20coffee%20and%20hear%20about%20it.%20-Joe"
        title="Email"
      >
        <div id="email-container-top">
          <svg
            id="email-icon-top"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            viewBox="0 0 24 24"
            class=""
          >
            <g>
              <path
                d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z"
                data-original="#000000"
                class="active-path"
                data-old_color="#000000"
                fill="#FFF"
              />
              <path
                d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z"
                data-original="#000000"
                class="active-path"
                data-old_color="#000000"
                fill="#FFF"
              />
            </g>
          </svg>
        </div>
      </a>
      <div class="small-social-content">
        <a
          aria-label="My LinkedIn"
          target="_blank"
          rel="noopener"
          href="https://www.linkedin.com/in/joelangenderfer/"
          title="LinkedIn"
        >
          <i id="social-logo-left" nz-icon nzType="linkedin" nzTheme="fill"></i>
        </a>
        <a
          aria-label="My Gitlab"
          target="_blank"
          rel="noopener"
          href="https://gitlab.com/cojoe/"
          title="Gitlab"
        >
          <i id="social-logo" nz-icon nzType="gitlab" nzTheme="fill"></i>
        </a>
        <a
          aria-label="My Instagram"
          target="_blank"
          rel="noopener"
          href="https://www.instagram.com/_joelang/"
          title="Instagram"
        >
          <i id="social-logo" nz-icon nzType="instagram" nzTheme="fill"></i>
        </a>
        <a
          aria-label="My Twitter"
          target="_blank"
          rel="noopener"
          href="https://twitter.com/_J16R"
          title="Twitter"
        >
          <i id="social-logo" nz-icon nzType="twitter" nzTheme="outline"></i>
        </a>
        <a
          aria-label="My Medium"
          target="_blank"
          rel="noopener"
          href="https://medium.com/@joelangenderfer"
          title="Medium"
        >
          <i id="social-logo" nz-icon nzType="medium-square" nzTheme="fill"></i>
        </a>
      </div>
    </div>
    <div class="spacer"></div>
    <a
      aria-label="Email Me"
      target="_blank"
      rel="noopener"
      href="mailto:joe.m.langen@gmail.com?Subject=Joe%20+%20[Your%20Name]&amp;body=If%20you%20have%20a%20cool%20startup%20or%20startup%20idea,%20I'd%20love%20to%20grab%20coffee%20and%20hear%20about%20it.%20-Joe"
      title="Email"
    >
      <div id="email-container">
        <svg
          id="email-icon"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          viewBox="0 0 24 24"
          class=""
        >
          <g>
            <path
              d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFF"
            />
            <path
              d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFF"
            />
          </g>
        </svg>
      </div>
    </a>
  </div>
</div>
